.topbar{
    top:0;
    left:0;
    width: 100vw;
    height: 70px;
    background: white;
    z-index: 5;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-bottom: 2px solid lightgrey;
}

.top-nav{
    justify-content: space-around;
    position: relative;
    left: 50px;
    width: 80%;
}



.signature{
    width: 300px;
    margin-top: 12px;
}

.menu-title{
    position: absolute;
    right: 30px;
}

.menu-text{
    justify-content: space-around;
    margin-right: 30px;
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;
    color: black;
    transition: 0.01s ease-in-out;
}

.menu-btn{
    position: absolute;
    left: 30px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 27px;
    cursor: pointer;
    background-color: white;
    transition: all 0.1s ease-in-out;
    border: 2px solid black;
    border-radius: 8px;
}


/* hamburger and animation */
.menu-btn_burger{
    width: 22px;
    height: 3px;
    background: black;
    border-radius: 15px;
    transition: all .3s ease-in-out;
}


.menu-btn_burger::before,
.menu-btn_burger::after{
    content: '';
    position: absolute;
    width: 22px;
    height: 3px;
    background: black;
    border-radius: 15px;
    transition: all .3s ease-in-out;
    box-shadow: grey;
}

.menu-btn_burger::before{
    transform: translateY(-6px)
}

.menu-btn_burger::after{
    transform: translateY(6px)
}

.menu-btn.open .menu-btn_burger{
    background: transparent;
}

.menu-btn.open .menu-btn_burger::before{
    transform: rotate(45deg) ;
}

.menu-btn.open .menu-btn_burger::after{
    transform: rotate(-45deg) ;
}

@media only screen and (max-width:900px){
    .menu{
        width: 100%;
        height: 340px;
    }
    .menu-option{
        font-size: 25px;
        font-weight: 500;
    }
    .menu-btn{
        display: flex;
    }
    .top-nav{
        display: none;
    }
    a:hover::before, a:focus::before {
        display: none;
    }
    .menu-title{
        right: 0px;
    }
}



.banner-list{
    list-style: none;
}
.banner-item {
    display: inline-flex;
}


.menu-text:hover, .menu-text:focus, .menu-text:active {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    color: white;
}


a {
    text-decoration: none;
    transition: color 0.1s, background-color 0.1s;
}
a {
    position: relative;
    display: block;
    padding: 15px 0;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;
    text-transform: uppercase;
    transition: color 0.1s,white 0.1s,padding 0.2s ease-in;
}
a::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 3px;
    left: 0;
    height: 3px;
    width: 75%;
    background-color: #000;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: color 0.1s,transform 0.2s ease-out;
    
}
a:active::before {
    background-color: #000;
}
a:hover::before, a:focus::before {
    transform-origin: left top;
    transform: scale(1, 1);
}