.art{
    max-height: 75vh;
    border: 1px solid black;
    max-width: 85vw;
}

.art-description{
    box-shadow: 0 0px 10px 15px rgb(253, 253, 253);
}

@media only screen and (max-width:700px){
    .img-container{
        margin: 0;
        background: none;
        padding-left: 0px;
        padding-right: 0px;
    }
}