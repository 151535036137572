.portrait{
    max-height: 80vh;
    border: 1px solid black;
    max-width: 85vw;
    margin: auto;
}


.about-text{
    line-height: 50px;
    font-family: 'Barlow';
    margin: auto;
    border: 1px solid black;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    line-break: anywhere;
}



.about-centered { 
    overflow:hidden;
  }
  
  .about-centered div {
     min-height: 200px;
     padding: 10px;
  }
  #one {
    float:left; 
    margin-right:20px;
    width:40%;
  }
  #two { 
    overflow:hidden;
    min-height:100px;
    width: 50vw;
  }
  
  @media screen and (max-width: 1200px) {
     #one { 
      float: none;
      margin-right:0;
      width:85vw;
      border:0;
    }
    #two {
        width: 85vw;
        max-width: 85vw;
    }

  }