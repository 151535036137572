.contact-form{
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
}    

.form-input{
    width: 400px;
    height: 30px;
    font-size: 14px;
    font-family: 'Barlow';
    border-radius: 5px;
}
.form-about{
    box-shadow: 0 0px 10px 15px rgb(253, 253, 253);
}

.form-message{
    width: 400px;
    height: 200px;
    font-size: 14px;
    border-radius: 5px;
    font-family: 'Barlow';
}

.form-button{
    width: 160px;
    height: 40px;
    background-color: white;
    border:1px solid black;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.form-label{
    margin-bottom: 20px;
}
    
@media only screen and (max-width:700px){
    .form-input{
        width: 75vw;
    }
    .form-message{
        width: 75vw;
    }
}