.page-container{
    height:calc(100% - 70px);
    height: -webkit-calc(100% - 70px);
    height: -moz-calc(100% - 100px);
    width: 100vw;
    position:fixed;
    top: 70px;
    left: 0px;
    color: black;
    display: block;
    z-index: 3;
    justify-content: center;
    align-items: center;
    overflow-x:hidden;
    overflow-wrap: break-word;
    background-color: white;
    background-image: url('../../../public/cells.jpg');
    background-size: 100vw;
    background-repeat: repeat-y;
}

html {
    scroll-behavior: smooth;
  }


.sub-container{
    height: 100%;
    width: 90vw;
    position:relative;
    top: 0px;
    margin: auto;
}

h1{
    background-color:rgba(255, 255, 255, 1);
    padding: 5px;
    border-radius: 3px;
    border-width: 10px;
    width: -moz-fit-content;
    width: fit-content;
    display: table;
    box-shadow: 0 0px 10px 15px rgb(253, 253, 253);
}

h2{
    background-color:rgba(255, 255, 255, 1);
    padding: 5px;
    border-radius: 3px;
    border-width: 10px;
    width: -moz-fit-content;
    width: fit-content;
    display: table;
    box-shadow: 0 0px 10px 15px rgb(253, 253, 253);
}

.page-heading{
    font-size: 25px;
}


.centered{
    display: flex;
    flex-direction: column;
    align-items: center;
}


p{
    line-height: 30px;
    inline-size: 90%;
    font-family: 'Barlow';

    background-color:rgba(255, 255, 255, 1);
    padding: 5px;
    border-radius: 3px;
    border-width: 10px;
    width: -moz-fit-content;
    width: fit-content;
    display: table;
}

@media only screen and (max-width:700px){
    .page-container{
        background-size: 700px;
        background-repeat: repeat-y;
    }
}